import {
  Construction,
  Drainage,
  Excavation,
  Housing,
  RoadConstruct,
  building_p,
  infastructure,
} from "assets/image";
import classNames from "classnames";
import { ArrowLeft } from "iconsax-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const CompletedProjects = () => {
  const navigate = useNavigate();
  return (
    <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div
          className="flex items-center gap-2 mb-10 cursor-pointer"
          onClick={() => navigate("/")}>
          <ArrowLeft className={classNames("h-5 w-5 text-primary")} /> Back
        </div>

        <div className="max-w-2xl mx-auto text-center mt-12 lg:mt-24">
          <h2 className="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl">
            Completed Projects
          </h2>
        </div>

        <div className="grid-cols-1 mt-12 lg:mt-24 gap-y-12 gap-x-6 grid md:grid-cols-3 gap-4 w-full text-white">
          {/*  */}
          {/* <div className="flex flex-col items-center w-full max-w-full space-y-3 p-4 pb-8 bg-kpsl_hotel_1 bg-center bg-cover min-h-[300px] relative group cursor-pointer">
            <div className="h-fit w-full absolute bottom-0 bg-primary/70 flex items-start">
              <div className="p-4">
                <div className="text-sm mt-2">
                  <span className="text-white p-2 font-bold rounded-l">
                    KPSL Hotel
                  </span>
                </div>
              </div>
            </div>
          </div> */}
          {/*  */}
          {/* <div className="flex flex-col items-center w-full max-w-full space-y-3 p-4 pb-8 bg-kpsl_hotel_2 bg-center bg-cover min-h-[300px] relative group cursor-pointer">
            <div className="h-fit w-full absolute bottom-0 bg-primary/70 flex items-start">
              <div className="p-4">
                <div className="text-sm mt-2">
                  <span className="text-white p-2 font-bold rounded-l">
                    KPSL Hotel
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default CompletedProjects;
